export default function NavSelectedSVG() {
  return (
    <svg width="20" height="6" viewBox="0 0 20 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1.16569C0 0.264367 1.03762 -0.245367 1.76655 0.284765C6.63318 3.82413 13.3668 3.82413 18.2334 0.284765C18.9624 -0.245367 20 0.264367 20 1.16569C20 1.51852 19.8267 1.85329 19.5414 2.06082C13.9083 6.15756 6.09165 6.15756 0.458629 2.06082C0.17328 1.85329 0 1.51852 0 1.16569Z"
        fill="#5B8EF4"
      />
    </svg>
  );
}
